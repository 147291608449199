import { useHoverState } from "@/hooks/useHovered";
import { cn } from "@/lib/utils";
import { validateString } from "@/lib/validations/reactHookFormValidations";
import autofillTooltipText from "@/utils/autofillTooltipText";
import camelOrSnakeToTitleCase from "@/utils/camelOrSnakeToTitleCase";
import { formatPhoneNumberAmerica } from "@/utils/stringManipulation";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { cva, type VariantProps } from "class-variance-authority";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { FieldErrors, FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form";
import { BiDollar } from "react-icons/bi";
import { Button } from "../ui/Button";
import { HoverCard, HoverCardContent, HoverCardProps, HoverCardTrigger } from "../ui/hover-card";
import { LabelAbove } from "../ui/LabelAbove";
import TextCarousel from "../ui/TextCarousel";
import { AlternateAIFieldValueIcon } from "./AlternateAIFieldValueIcon";
import { FeatureIcons } from "./FeatureIcons";

//A variant for label within and above the input
const inputVariants = cva("peer transition-all w-full rounded-md focus:ring-1 focus:ring-skyBlue", {
  variants: {
    variant: {
      default:
        "bg-background text-primary border-[1px] border-border focus:border-[1px] font-normal outline-none disabled:hover:border-border disabled:cursor-not-allowed disabled:text-secondary-dark disabled:opacity-75 disabled:bg-secondary-light",
      purple: "bg-purple hover:bg-darkPurple text-primary-light w-full justify-center min-w-full",
      destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      outline:
        "outline outline-1 outline-border hover:outline-secondary-dark bg-background hover:bg-accent hover:text-accent-foreground focus:outline-skyBlue",
      secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
      shortText:
        "bg-background text-primary hover:border-neutral-500 border border-primary focus:border-[1px] font-normal outline-none disabled:cursor-not-allowed disabled:opacity-70 w-full max-w-[400px]",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      link: "text-primary underline-offset-4 hover:underline",
      labelAbove: "",
    },
    width: {
      default: "",
      full: "w-full justify-center min-w-full bg-black",
      fit: "w-fit max-w-min",
    },
    size: {
      default: "h-[65px]",
      sm: "h-[38px] rounded-md px-3",
      md: "h-[42px] rounded-md px-5",
      lg: "h-[46px] rounded-md px-8",
      icon: "h-10 w-10",
      slim: "h-[38px] rounded-md px-5 w-full",
      fit: "w-fit max-w-full h-fit",
      full: "w-full h-full justify-center min-w-full",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
    width: "default",
  },
});

const checkboxAndRadioVariants = cva(
  "form-checkbox cursor-pointer disabled:bg-secondary-dark transition-all bg-background border-[1px] rounded-[2px] border-border hover:border-neutral-500 disabled:cursor-not-allowed disabled:opacity-70",
  {
    variants: {
      variant: {
        default:
          "bg-background text-primary hover:border-neutral-500 border border-primary focus:border-[1px] font-normal outline-none disabled:cursor-not-allowed disabled:opacity-70",
        purple: "text-purple hover:text-darkPurple focus:ring-[0px] focus:outline-[1px]",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        shortText: "w-full max-w-[400px]",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        labelAbove: "",
      },
      width: {
        default: "",
        full: "w-full justify-center min-w-full",
        fit: "w-fit max-w-min",
      },
      size: {
        default: "",
        sm: "h-[40px] rounded-md px-3",
        md: "h-[44px] rounded-md px-5",
        lg: "h-[48px] rounded-md px-8",
        icon: "h-10 w-10",
        slim: "h-[40px] rounded-md px-3",
        fit: "rounded-[2px]",
        full: "w-full h-full items-stretch justify-center min-w-full",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      width: "default",
    },
  }
);

export interface InputProps
  extends React.ButtonHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants>,
    VariantProps<typeof checkboxAndRadioVariants> {
  id: string;
  labelAbove?: string | boolean;
  label?: string;
  labelClassName?: string;
  labelSelectable?: boolean;
  labelIcons?: ReactNode;
  parents?: string[];
  dependents?: string[];
  options?: string[] | undefined;
  historicValuesEnabled?: boolean;
  historicValues?: string[];
  historicValueLabels?: string[];
  separateBy?: string;
  showLabelIconsOnlyOnFocus?: boolean;
  showRefBoxButtonsInitial?: boolean;
  showExpandUpwardsButton?: boolean;
  showExpandSideButton?: boolean;
  processText?: (text: string) => string;
  placeholder?: string;
  specialPlaceholder?: string;
  otherType?: "text" | "password" | "email" | "checkbox" | "radio" | "number" | "date" | "time" | "datetime-local" | "file";
  accept?: string | undefined;
  name?: string;
  disabled?: boolean;
  formatPrice?: boolean;
  required?: boolean;
  maxLength?: number;
  checked?: boolean;
  defaultChecked?: boolean;
  defaultValue?: string;
  value?: string | number;
  processBeforeOnChangeCall?: (value: string) => string;
  onChange?: (e: any) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  register?: UseFormRegister<FieldValues>;
  errors?: FieldErrors<any>;
  error?: string;
  validationSchema?: RegisterOptions;
  validationMessageId?: string;
  innerRef?: React.Ref<HTMLInputElement>;
  inputRef?: React.Ref<HTMLInputElement>;
  ref?: React.Ref<HTMLInputElement>;
  stopMouseEventPropagation?: boolean;
  stopKeyPropagation?: boolean;
  presentAsStatic?: boolean;
  className?: string | "";
  classNameInputBox?: string | "";
  additionalInputClassName?: string | "";
  style?: React.CSSProperties;
  makeUpperCase?: boolean;
  children?: JSX.Element | null;
  disableHoverState?: boolean;
  tooltip?: string;
  tooltipDelay?: number;
  tooltipVariant?: HoverCardProps["variant"];
  tooltipSize?: HoverCardProps["size"];
  tooltipClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      labelAbove,
      label,
      labelClassName,
      labelSelectable,
      labelIcons,
      parents = [],
      dependents = [],
      options = undefined,
      historicValuesEnabled = false,
      historicValues = [],
      historicValueLabels = [],
      separateBy = "\n",
      showLabelIconsOnlyOnFocus = true,
      placeholder,
      specialPlaceholder,
      otherType = "text",
      accept = undefined,
      variant = undefined,
      size = undefined,
      width = undefined,
      name,
      disabled,
      formatPrice,
      required,
      maxLength,
      checked,
      defaultChecked,
      value,
      defaultValue,
      processBeforeOnChangeCall,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onPaste,
      register,
      errors,
      error,
      showRefBoxButtonsInitial = true,
      showExpandUpwardsButton = false,
      showExpandSideButton = true,
      processText,
      validationSchema,
      validationMessageId = `${id}-validation-message`,
      innerRef,
      inputRef,
      stopMouseEventPropagation = true,
      stopKeyPropagation = false,
      presentAsStatic = false,
      className,
      classNameInputBox,
      additionalInputClassName,
      style,
      disableHoverState = true,
      tooltip,
      tooltipDelay = 700,
      tooltipVariant,
      tooltipSize,
      tooltipClassName,
      makeUpperCase = false,
      children,
      ...props
    },
    ref
  ) => {
    const [showLabelIcons, setShowLabelIcons] = useState<boolean>(showLabelIconsOnlyOnFocus !== null ? !showLabelIconsOnlyOnFocus : true);
    const [showRefBoxButtons, setShowRefBoxButtons] = useState<boolean>(showRefBoxButtonsInitial);

    const [hasText, setHasText] = useState(false);
    const [inputValue, setInputValue] = useState(typeof value === "number" ? String(value) : value ?? "");
    const [checkedValue, setCheckedValue] = useState(checked ?? false);
    const [localErrorMsg, setLocalErrorMsg] = useState<null | string>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    //const [labelAboveInput, setLabelAboveInput] = useState(false)

    const localInputRef = useRef<HTMLInputElement | null>(null);

    ////console.log("Erros1019", errors);
    const hasErrors = (errors && errors[id]) || (error && error?.length > 0) || localErrorMsg !== null;
    let errorMessage;
    if (errors && errors[id] && errors.hasOwnProperty(id)) {
      const errorMessageTmp = errors[id]?.message;

      // Check if errorMessage is a string before setting the state
      if (typeof errorMessageTmp === "string" && errorMessageTmp.length > 0) {
        ////console.log("Setting error message", id, errorMessageTmp, errors, errors[id]);
        errorMessage = errorMessageTmp;
      } else {
        // Handle other types or reset the state as needed
        errorMessage = "Field Required";
      }
    } else if (error && error?.length > 0) {
      errorMessage = error;
    } else {
      errorMessage = undefined;
    }

    if (hasErrors) {
      presentAsStatic = false;
    }

    if (typeof labelAbove === "boolean" && labelAbove) {
      labelAbove = label ?? camelOrSnakeToTitleCase(label ?? name ?? id);
    }

    //React.useEffect(() => {
    //	if (initialValue) {
    //			setInputValue(initialValue);
    //		}
    //	}, [initialValue]);

    useEffect(() => {
      if (value !== undefined && value !== null) {
        // If registerOnChange is a function, create a synthetic event and pass it
        // Please note that this approach assumes that registerOnChange only uses the value and name from the event object. If registerOnChange uses other properties from the event object (like event.target.type or event.currentTarget), you'll need to include those in the synthetic event as well.
        const event = {
          target: {
            value: value,
            name: registerName, // Assuming registerName is the name of the input
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>;
        handleInputChange(event);
      }
    }, [value]);

    useEffect(() => {
      if (defaultValue) {
        setInputValue(defaultValue);
      }
    }, [defaultValue]);

    useEffect(() => {
      setCheckedValue(defaultChecked ?? false);
    }, [defaultChecked]);

    useEffect(() => {
      setCheckedValue(checked ?? false);
    }, [checked]);

    useEffect(() => {
      //console.log("InputValue checkbox", inputValue);
    }, [inputValue]);

    ////console.log("Inputs value = ", value);
    //setInputValue(value || "");

    //errorMessage = errors && String(errors[id]?.message);

    const handleInputChange = (e: any) => {
      if (stopKeyPropagation && typeof e === "object" && e.stopPropagation) {
        e.stopPropagation();
      }

      if (typeof e === "string") e = { target: { value: e } };
      if (otherType === "checkbox") {
        setCheckedValue(e.target.checked);
        console.log("Checkbox e event", e);
        const newVal: boolean = Boolean(e.target.checked);

        e = {
          target: {
            value: newVal,
            name: registerName, // Assuming registerName is the name of the input
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>;
      } else {
        if (otherType === "password" && showPassword) {
          //dont show password while typing
          setShowPassword(false);
        }
        //normal texts input type
        let localInputValue = String(e.target.value);
        ////console.log("localInputValue", localInputValue);

        if (processText) {
          localInputValue = processText(localInputValue);
        }

        if (localInputValue.length > inputValue.length) {
          //only format if adding characters
          if (id.toLowerCase().includes("name")) {
            //remove leading spaces
            localInputValue = localInputValue.replace(/^\s+/, "");
            //capitalize first letter
            localInputValue = localInputValue.charAt(0).toUpperCase() + localInputValue.slice(1);
          }
          if (id.toLowerCase().includes("phone")) {
            if (id.toLowerCase().includes("ext")) {
              //remove any non numeric characters
              localInputValue = localInputValue.replace(/\D/g, "");
            } else {
              localInputValue = formatPhoneNumberAmerica(localInputValue);
            }
          }
          if (makeUpperCase) {
            localInputValue = localInputValue.toUpperCase();
          }
        }
        e = {
          target: {
            value: localInputValue,
            name: registerName, // Assuming registerName is the name of the input
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>;

        //if there is a validationSchema and the errors are not handle by react hook form or other errors being passed, then lets validate the input
        if (validationSchema && !register && !disabled && !presentAsStatic && !(errors && errors[id]) && !error) {
          setLocalErrorMsg(validateString(localInputValue, validationSchema));
        }
        //value = localInputValue;
        ////console.log("inputValue", localInputValue);
        setHasText(localInputValue.length > 0);
        setInputValue(localInputValue);
      }
      if (processBeforeOnChangeCall) {
        e.target.value = processBeforeOnChangeCall(e.target.value);
      }

      if (e?.target?.name && e.target.name.includes("sites")) {
        //debugger;
      }

      onChange && onChange(e);
      registerOnChange && registerOnChange(e);
    };

    const handleOnInput = (e: any) => {
      ////console.log("OnInput", e);
    };

    let registerOnChange: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
    let registerOnBlur: ((e: React.FocusEvent<HTMLInputElement>) => void) | undefined;
    let registerName: string | undefined;
    let registerRef: React.Ref<HTMLInputElement> | undefined;

    // Check if the register function exists before invoking it
    if (register && !registerOnChange) {
      if (!validationSchema && required) {
        validationSchema = { required: "This field is required" };
      }

      const registerResult = register(id, validationSchema);
      registerOnChange = registerResult.onChange;
      registerOnBlur = registerResult.onBlur;
      registerName = registerResult.name;
      registerRef = registerResult.ref;
    }
    //const { ref, ...rest } = registerInput || {};
    //////console.log("registerInput",registerInput)

    //flex-col so error message is under neither

    const inputClassName = cn(
      inputVariants({ variant, size, width }),
      {
        "pl-9": formatPrice,
        "pl-[10px]": !formatPrice,
        "border-rose-500": hasErrors,
        "border-border hover:border-border-hovered": !hasErrors,
        "focus:border-rose-500": hasErrors,
        "focus:border-skyBlue": !hasErrors,
        "pl-[14.5px]": label,
      },
      "transition-all duration-200 ease-in-out"
    );

    const checkboxAndRadioClassName = cn(
      checkboxAndRadioVariants({ variant, size, width }),
      {
        "pl-9": formatPrice, // Add padding if necessary
        "border-rose-500": hasErrors,
        "focus:border-rose-500": hasErrors,
        "bg-background/50 text-secondary-dark/90 hover:text-secondary-dark focus:ring-transparent focus:ring-0": disabled,
      },
      className,
      "focus:ring-[2px] focus:ring-skyBlue focus:ring-offset-1 mr-1"
    );

    const inputStyle: React.CSSProperties = {
      ...style,
      ...(otherType !== "checkbox"
        ? {
            //outline: "none",
            //border: "none",
            padding: maxLength === 1 ? 4 : label && "1rem", //inner input text spacing
            paddingLeft: maxLength === 1 ? 0 : presentAsStatic ? "0px" : undefined, // inner input text spacing
            paddingRight: maxLength === 1 ? 0 : presentAsStatic ? "0px" : undefined, // inner input text spacing
            paddingTop: presentAsStatic ? "1px" : label ? "10px" : undefined, // inner input text spacing
            paddingBottom: label ? "0px" : undefined, // inner input text spacing
            textAlign: maxLength === 1 ? "center" : undefined,
          }
        : {
            textAlign: "center", // Reset textAlign for checkbox type
          }),
      border: presentAsStatic ? "none" : undefined,
    };

    const mergedRef = (node: any) => {
      // Assign the node to the forwardRef
      if (ref) {
        if (typeof ref === "function") {
          ref(node);
        } else {
          ref.current = node;
        }
      }
      // Assign additional refs if available
      if (inputRef) {
        if (typeof inputRef === "function") {
          inputRef(node);
        }
      }
      if (registerRef) {
        if (typeof registerRef === "function") {
          registerRef(node);
        }
      }
      if (innerRef) {
        if (typeof innerRef === "function") {
          innerRef(node);
        }
      }
    };

    useEffect(() => {
      if (otherType === "checkbox" && inputValue.length > 0) {
        console.error("Checkbox input should not have a value");
      }
    }, [inputValue]);

    //if (id.includes("Dentist")) debugger;

    const divRef = useRef<HTMLDivElement>(null);
    useHoverState([divRef], {
      setSettersEnterTrue: [setShowLabelIcons, setShowRefBoxButtons, setIsHovered],
      setSettersLeaveFalse: [setShowLabelIcons, setShowRefBoxButtons, setIsHovered],
      disableSettersEnter: false,
      disableSettersLeave: false,
      disableSetters: disableHoverState ? true : false,
    });

    const labelIconsClassNames = "text-tertiary-dark hover:text-skyBlue hover:cursor-pointer";

    const mainJSX = (
      <div
        ref={divRef}
        className={cn(
          "group flex h-full flex-col justify-start",
          {
            "justify-center": otherType === "checkbox",
          },
          className
        )}
        style={style}
        onClick={(e) => {
          if (stopMouseEventPropagation) {
            e.stopPropagation();
          }
        }}
      >
        {formatPrice && <BiDollar size={24} className="absolute left-2 top-5 text-neutral-700" />}
        <div className={cn("flex gap-x-1 pb-0 text-primary", { "p-0": otherType === "checkbox" || (!labelAbove && (!showLabelIcons || disabled)) })}>
          <LabelAbove label={labelAbove} className={cn({ "-mb-[3px]": options && options.length > 0 })} />
          {!disabled && showLabelIcons && (
            <div className="flex items-center gap-x-2">
              {historicValuesEnabled && (
                <AlternateAIFieldValueIcon
                  handleAlternativeSelect={(value) => handleInputChange(value)}
                  textState={inputValue}
                  alternateValues={historicValues}
                  alternateValuesLabels={historicValueLabels}
                  triggerClassName={labelIconsClassNames}
                />
              )}
              <FeatureIcons
                tooltipText={autofillTooltipText(parents, dependents)}
                numRelated={parents?.length > dependents?.length ? parents?.length : dependents?.length}
                iconClassName={labelIconsClassNames}
                className="w-[260px]"
                side="top"
                iconSize={16}
              />
            </div>
          )}
        </div>
        {options && options.length > 0 && (
          <TextCarousel
            maxItemWidthPX={130}
            showButtons={showRefBoxButtons}
            showExpandUpwardsButton={showExpandUpwardsButton}
            showExpandSideButton={showExpandSideButton}
            textFieldText={inputValue}
            handleTextChange={handleInputChange}
            options={options}
          />
        )}
        <div className={cn("relative", { "flex items-center": otherType === "checkbox" })}>
          <input
            id={id}
            key={id}
            name={name ?? registerName ?? id}
            autoComplete={name || registerName || id}
            type={showPassword ? undefined : otherType}
            accept={accept}
            defaultChecked={otherType === "checkbox" ? (inputValue.length > 0 ? Boolean(inputValue) : checkedValue) : undefined}
            checked={disabled ? (value ? Boolean(value) : checked) : checkedValue}
            disabled={disabled}
            placeholder={specialPlaceholder ? specialPlaceholder : placeholder ? placeholder : undefined}
            required={required}
            aria-invalid={!!hasErrors} // Indicates invalid state to screen readers
            aria-describedby={validationMessageId} // Links to the validation message
            maxLength={maxLength}
            //defaultValue={defaultValue}
            value={otherType === "checkbox" ? undefined : inputValue}
            onKeyDown={(e) => {
              if (stopKeyPropagation && e.key === " ") {
                e.stopPropagation();
              }
              onKeyDown && onKeyDown(e);
            }}
            onChange={(e) => {
              if (!disabled) handleInputChange(e);
            }}
            onFocus={onFocus}
            onBlur={(e) => {
              onBlur?.(e);
              registerOnBlur?.(e);
            }}
            ref={mergedRef}
            //{...(register ? registerInput : {})}
            onPaste={onPaste}
            className={cn("", otherType === "checkbox" || otherType === "radio" ? checkboxAndRadioClassName : inputClassName, classNameInputBox)}
            style={inputStyle}
            {...props}
          />
          {otherType === "password" && (
            <Button
              variant="blank"
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-tertiary-dark"
              onClick={(e: any) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <VisibilityOutlinedIcon sx={{ fontSize: 22 }} /> : <VisibilityOffOutlinedIcon sx={{ fontSize: 22 }} />}
            </Button>
          )}
          {label && otherType !== "checkbox" && (
            <label
              className={cn(
                "text-md pointer-events-none absolute top-5 z-0 origin-[0] -translate-y-3  transform  duration-150",
                formatPrice ? "left-9" : "left-4",
                "peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75",
                hasText ? "-translate-y-4 scale-75" : "translate-y-0 scale-100",
                hasErrors ? "text-rose-500" : "text-zinc-400",
                labelClassName
              )}
            >
              {label}
            </label>
          )}
          {children ||
            (label && (otherType === "checkbox" || otherType === "radio") && (
              //This is for text beside checkbox
              <div
                className={cn("flex w-full items-start justify-start", { "cursor-pointer underline": labelSelectable && isHovered })}
                onClick={() => labelSelectable && handleInputChange({ target: { checked: !checkedValue } })}
              >
                {label && <div>{label}</div>}
                {children}
              </div>
            ))}
        </div>
        {hasErrors && (
          <div
            role="alert"
            aria-live="assertive"
            className={cn("", { flex: otherType === "checkbox" })}
            id={validationMessageId}
            data-testid={validationMessageId} // For testability
          >
            <span className="left-0 z-40 my-1 text-sm text-rose-500">{String(errorMessage ?? localErrorMsg ?? "Field invalid")}</span>
          </div>
        )}
      </div>
    );

    // Check if toolTip has text and wrap buttonJSX accordingly
    if (tooltip) {
      return (
        <HoverCard openDelay={tooltipDelay}>
          <HoverCardTrigger asChild>{mainJSX}</HoverCardTrigger>
          <HoverCardContent className={cn("w-full", tooltipClassName)} variant={tooltipVariant} size={tooltipSize}>
            {tooltip}
          </HoverCardContent>
        </HoverCard>
      );
    }

    return mainJSX;
  }
);
Input.displayName = "Input";

export { Input, inputVariants };
